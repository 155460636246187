/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class NavigationFilters {
  includeArchived?: boolean;
  resId?: string;
  title?: string;

  constructor({
      includeArchived, 
      resId, 
      title
  }: {
      includeArchived?: boolean;
      resId?: string;
      title?: string;
  } = {}) {
    this.includeArchived = includeArchived;
    this.resId = resId;
    this.title = title;
  }
}

export namespace NavigationFilters {

}


/**
 * NavigationFilters form builder
 *
 */
export class NavigationFiltersFormBuilder extends AbstractFormBuilder<NavigationFiltersFormBuilder> {

  className = 'NavigationFilters';

  abstractFormBuilder!: AbstractFormBuilder<NavigationFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): NavigationFilters {
    return new NavigationFilters();
  }

  public includeArchived(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('includeArchived', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIncludeArchived() {
    super.removeField('includeArchived');
    return this;
  }

  public resId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('resId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutResId() {
    super.removeField('resId');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }


  public all(): NavigationFiltersFormBuilder {
    return this
    .includeArchived() 
    .resId() 
    .title() 
;
  }
}

