/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentRelationshipSpecification {
  id?: string;
  ltrGettyId?: string;
  ltrRendering?: string;
  relType?: AgentRelationshipSpecification.RelTypeEnum;
  rtlGettyId?: string;
  rtlRendering?: string;

  constructor({
      id, 
      ltrGettyId, 
      ltrRendering, 
      relType, 
      rtlGettyId, 
      rtlRendering
  }: {
      id?: string;
      ltrGettyId?: string;
      ltrRendering?: string;
      relType?: AgentRelationshipSpecification.RelTypeEnum;
      rtlGettyId?: string;
      rtlRendering?: string;
  } = {}) {
    this.id = id;
    this.ltrGettyId = ltrGettyId;
    this.ltrRendering = ltrRendering;
    this.relType = relType;
    this.rtlGettyId = rtlGettyId;
    this.rtlRendering = rtlRendering;
  }
}

export namespace AgentRelationshipSpecification {

  export type RelTypeEnum = 'Familiar' | 'Professional' | 'Other';
  export const RelTypeEnum = {
      Familiar: 'Familiar' as RelTypeEnum,
      Professional: 'Professional' as RelTypeEnum,
      Other: 'Other' as RelTypeEnum,
  }
  export const RelTypeEnumValues = [
      RelTypeEnum.Familiar, 
      RelTypeEnum.Professional, 
      RelTypeEnum.Other
  ];
}


/**
 * AgentRelationshipSpecification form builder
 *
 */
export class AgentRelationshipSpecificationFormBuilder extends AbstractFormBuilder<AgentRelationshipSpecificationFormBuilder> {

  className = 'AgentRelationshipSpecification';

  abstractFormBuilder!: AbstractFormBuilder<AgentRelationshipSpecification>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentRelationshipSpecification {
    return new AgentRelationshipSpecification();
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public ltrGettyId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('ltrGettyId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLtrGettyId() {
    super.removeField('ltrGettyId');
    return this;
  }

  public ltrRendering(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('ltrRendering', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLtrRendering() {
    super.removeField('ltrRendering');
    return this;
  }

  public relType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('relType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRelType() {
    super.removeField('relType');
    return this;
  }

  public rtlGettyId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('rtlGettyId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRtlGettyId() {
    super.removeField('rtlGettyId');
    return this;
  }

  public rtlRendering(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('rtlRendering', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRtlRendering() {
    super.removeField('rtlRendering');
    return this;
  }


  public all(): AgentRelationshipSpecificationFormBuilder {
    return this
    .id() 
    .ltrGettyId() 
    .ltrRendering() 
    .relType() 
    .rtlGettyId() 
    .rtlRendering() 
;
  }
}

